import {
    ClientLoaderFunctionArgs,
    Links,
    Meta,
    Scripts,
    ScrollRestoration,
    replace,
    useLoaderData,
    useMatches,
    useNavigate,
    useOutlet,
} from '@remix-run/react'
import './tailwind.css'
import { AnimatePresence, motion } from 'framer-motion'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from './components/Navbar'
import { globalState } from './lib/state'
import { QueryClientProvider } from '@tanstack/react-query'
import { client, queryClient, sendOutbox } from './lib/client'
import i18next, { t } from 'i18next'
import { Capacitor } from '@capacitor/core'
import { jwtDecode } from 'jwt-decode'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/node/AdapterDayjs'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import Splash from './components/Splash'
import { getUserDetails } from './lib/user'
import OperatorFooter from './components/OperatorFooter'
import SubscriptionWarning, {
    SubscriptionToast,
} from './components/SubscriptionWarning'
import { twMerge } from 'tailwind-merge'
import { authStaging } from './utils/functions'
import logout from './utils/logout'

export function meta() {
    const metaTags = []
    const dontIndex = {
        name: 'robots',
        content: 'noindex',
    }
    if (import.meta.env.VITE_IS_STAGING) {
        metaTags.push(dontIndex)
    }
    return []
}
export function Layout({ children }: { children: React.ReactNode }) {
    return (
        <html lang="he">
            <head>
                <link
                    rel="icon"
                    type="image/png"
                    href="/favicon-96x96.png"
                    sizes="96x96"
                />
                <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
                <link rel="shortcut icon" href="/favicon.ico" />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                />
                <meta name="apple-mobile-web-app-title" content="Benefit" />
                <link rel="manifest" href="/site.webmanifest" />
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, viewport-fit=cover"
                />
                {import.meta.env.VITE_IS_STAGING && (
                    <meta name="robots" content="noindex" />
                )}
                <title>Benefit For Pools</title>

                <Meta />
                <Links />
            </head>{' '}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <body className="flex h-full flex-col items-center font-Rubik">
                    {children}
                    <ScrollRestoration />
                    <Scripts />
                </body>
            </LocalizationProvider>
        </html>
    )
}

let checkTokenInThisSession = false
export const isNativeApp =
    Capacitor.isNativePlatform() || import.meta.env.VITE_MOCK_MOBILE === 'true'

export async function clientLoader(req: ClientLoaderFunctionArgs) {
    authStaging()

    const token = globalState.getState().accessToken
    const landingPage =
        req.request.url == location.origin + '/' ||
        req.request.url == location.origin

    const onPublicPages =
        req.request.url.includes('/accessibility') ||
        req.request.url.includes('/terms') ||
        req.request.url.includes('/auth/') ||
        landingPage

    if (window.self !== window.top) {
        if (window.location.pathname.includes('/payment-fail/')) {
            parent.postMessage('payment-fail', '*')
        }
        if (window.location.pathname.includes('/payment-success/')) {
            parent.postMessage('payment-success', '*')
        }
    }

    if (onPublicPages && !isNativeApp) {
        return true
    }

    if (!token && !onPublicPages) {
        if (isNativeApp) {
            return replace('/auth/login')
        } else {
            return replace('/auth/register')
        }
    }

    if (token && onPublicPages) {
        if (isNativeApp) {
            return replace('/operator')
        }
        return replace('/dashboard')
    }

    const initialSplashScreen =
        isNativeApp && !window.location.pathname.includes('/auth')

    return { initialSplashScreen, token }
}

async function checkTokenValidity(token: string) {
    try {
        if (!token) {
            return
        }
        if (!checkTokenInThisSession) {
            const user = await client.api.apiUsersMeRetrieve({
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            if (
                (user?.error && user.error?.data?.code === 'token_not_valid') ||
                user?.error?.data?.code === 'user_not_found'
            ) {
                logout()
            }
            const decodedToken = token ? jwtDecode(token) : null
            if (!decodedToken) {
                logout()
            }
            const tokenExpirationDate = new Date(decodedToken.exp * 1000)
            const weekFromExpirationDate = new Date(
                tokenExpirationDate
            ).setDate(tokenExpirationDate.getDate() - 7)

            if (weekFromExpirationDate < Number(new Date())) {
                const res = await client.apiTokenRefresh.apiTokenRefreshCreate({
                    refresh: globalState.getState().refreshToken,
                })
                globalState.getState().setAccessToken(res.access)
                client.http.instance.defaults.headers['Authorization'] =
                    'Bearer ' + token
            }
            checkTokenInThisSession = true
        } else {
            client.http.instance.defaults.headers['Authorization'] =
                'Bearer ' + token
        }

        return true
    } catch (err) {
        console.log({ err })
        logout()
    }
}

export default function App() {
    const handleFocus = useCallback(() => {
        sendOutbox()
    }, [])

    useEffect(() => {
        window.addEventListener('focus', handleFocus)

        return () => {
            window.removeEventListener('focus', handleFocus)
        }
    }, [])

    const { initialSplashScreen, token } = useLoaderData<typeof clientLoader>()
    const [showSplash, setShowSplash] = useState(initialSplashScreen)

    const navigate = useNavigate()
    useHandleOrganizationBranch(navigate, setShowSplash, token)

    const outlet = useOutlet()
    const matches = useMatches()
    const animationTrigger = matches[1]?.pathname

    return (
        <main
            className="flex min-h-screen w-full flex-col bg-grey-background md:flex-row"
            style={{ direction: i18next.language === 'he' ? 'rtl' : 'ltr' }}
        >
            <Splash key="splash" show={showSplash} />

            <ToastContainer
                rtl={i18next.language === 'he'}
                className={'text-start'}
                limit={1}
            />
            <QueryClientProvider client={queryClient}>
                <Navbar />
                <SubscriptionWarning />
                <SubscriptionToast />

                <AnimatePresence mode="wait">
                    <motion.div
                        key={animationTrigger}
                        className={twMerge('w-full')}
                        transition={{ duration: 0.2 }}
                        animate={{ opacity: 1 }}
                        initial={{ opacity: isNativeApp ? 1 : 0 }}
                        exit={{ opacity: isNativeApp ? 1 : 0 }}
                    >
                        {outlet}
                    </motion.div>
                </AnimatePresence>
                <OperatorFooter />
            </QueryClientProvider>
        </main>
    )
}

function useHandleOrganizationBranch(navigate, setShowSplash, token) {
    useEffect(() => {
        checkTokenValidity(token).then(() => {
            getUserDetails().then((user) => {
                let complex
                if (globalState.getState().currentPoolComplex) {
                    return
                }
                const isOperator = user.operator_in_organization_branch?.length
                if (isNativeApp && isOperator) {
                    complex = user.operator_in_organization_branch[0]
                } else if (user.user_manager_in_organization_branch?.length) {
                    complex = user.user_manager_in_organization_branch[0]
                } else if (user.manager_in_organization_branches?.length) {
                    complex = user.manager_in_organization_branches[0]
                } else {
                    if (isNativeApp && !isOperator) {
                        //user probably exists, but not affiliated
                        logout()
                    }
                    return navigate('/onboarding')
                }
                globalState.getState().setCurrentComplex(complex.id)
            })
        })

        setTimeout(() => {
            setShowSplash(false)
        }, 3000)
    }, [])
}

export function HydrateFallback() {
    return <Splash show key="splash" />
}
